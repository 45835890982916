<script lang="ts" setup>
import type { ILeaseSummary, ILeaseInfo } from '@register'
import { Product } from '@reports'
import LeasePageEmpty from '@register/components/LeasePageEmpty.vue'

interface Props {
  leaseSummary?: ILeaseSummary
  leaseInfo: ILeaseInfo
  product: Product
}

const props = defineProps<Props>()

const downloadingPDF = ref(false)
const printArea = ref<HTMLElement>()
async function onPDF() {
  if (!printArea.value) {
    return
  }
  downloadingPDF.value = true
  const name = `Lease - ${props.leaseInfo.name}.pdf`
  await useHTMLToPDF(name, printArea.value?.innerHTML)
  downloadingPDF.value = false
}
</script>

<template>
  <div class="flex w-full items-center justify-center">
    <section id="print-area" ref="printArea" class="w-full">
      <RegisterSummaryHeader :lease-info="leaseInfo">
        <template #actions>
          <RegisterSummaryActions
            :lease-info="leaseInfo"
            :downloading-pdf="downloadingPDF"
            @download-pdf="onPDF"
          />
        </template>
      </RegisterSummaryHeader>

      <template v-if="product === Product.LEASE_MANAGER">
        <LazySummaryLM
          v-if="leaseSummary?.leaseManager"
          :summary="leaseSummary.leaseManager"
          class="mt-4"
        />
        <LeasePageEmpty v-else title="Lease summary not available" />
      </template>
      <template v-else-if="product === Product.LEASE_REGISTER">
        <div v-if="leaseSummary?.leaseRegister" class="flex w-full flex-col">
          <RegisterSummaryFields
            v-if="leaseSummary.leaseRegister"
            :fields="leaseSummary.leaseRegister.fields ?? []"
          />

          <RegisterSummaryClauses
            v-if="leaseSummary.leaseRegister"
            :data="leaseSummary.leaseRegister.clausesAndDefinitions ?? []"
            title="Clauses and definitions"
          />

          <RegisterSummarySpecialConditions
            v-if="leaseSummary.leaseRegister?.specialConditions"
            :data="leaseSummary.leaseRegister.specialConditions"
            title="Special conditions"
          />
        </div>
        <LeasePageEmpty v-else title="Lease summary not available" />
      </template>

      <main class="relative w-full"></main>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
@media print {
  body {
    @apply m-0;
  }
}
</style>
